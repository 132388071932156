/* OurTeam.css */

/* Ensure consistent box-sizing */
*,
*::before,
*::after {
  box-sizing: border-box;
}

.ptf-team-member {
  margin: 1rem; /* Uniform spacing around each member */
  background: #ffffff;
  border-radius: 15px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.ptf-team-member:hover {
  transform: translateY(-10px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.15);
}

.ptf-team-member__image-wrapper {
  position: relative;
  overflow: hidden;
  border-radius: 15px 15px 0 0;
  margin-bottom: 2rem;
}

.ptf-team-member__image {
  width: 100%;
  height: auto;
  display: block;
  border-bottom: 5px solid #f0f0f0;
  transition: transform 0.5s ease;
}

.ptf-team-member__image-wrapper:hover .ptf-team-member__image {
  transform: scale(1.05);
}

.ptf-team-member__content {
  padding: 1.5rem;
  text-align: center;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ptf-team-member__name {
  font-size: 1.5rem;
  font-weight: bold;
  color: #333333;
  margin-bottom: 0.5rem;
}

.ptf-team-member__position {
  font-size: 1.1rem;
  font-weight: 600;
  color: #777777;
  margin-bottom: 1rem;
}

.ptf-team-member__description {
  font-size: 1rem;
  color: #555555;
  line-height: 1.6;
  margin-bottom: auto;
}

/* Fancy Heading and Intro Styling */
.fancy-heading {
  font-size: 3rem;
  font-weight: bold;
  color: #2c3e50;
  margin-bottom: 1.5rem;
  text-align: center;
}

.fancy-intro {
  font-size: 1.25rem;
  color: #555555;
  line-height: 1.8;
  margin-bottom: 2rem;
  text-align: center;
}

/* Add some spacing and separation between elements */
.fancy-team-section {
  padding: 5rem 0;
  background: #f9f9f9;

  /* Flexbox Layout */
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  /* Removed gap property */
}

.ptf-spacer {
  display: block;
  margin-bottom: 3rem;
}

/* Animated Block Styling */
.our-animated-block {
  opacity: 0;
  transition: opacity 0.6s ease, transform 0.6s ease;
}

[data-aos="fade"] {
  opacity: 1;
  transform: translateY(0);
}

/* Responsive Styling for Mobile Devices */
@media (max-width: 768px) {
  .fancy-heading {
    font-size: 2.5rem;
  }

  .fancy-intro {
    font-size: 1.1rem;
  }

  .ptf-team-member__name {
    font-size: 1.25rem;
  }

  .ptf-team-member__position {
    font-size: 1rem;
  }

  .ptf-team-member__description {
    font-size: 0.9rem;
  }

  /* Adjust margins for smaller screens */
  .ptf-team-member {
    margin: 0.75rem;
  }
}

@media (max-width: 576px) {
  .fancy-heading {
    font-size: 2rem;
  }

  .fancy-intro {
    font-size: 1rem;
  }

  .ptf-team-member__name {
    font-size: 1.1rem;
  }

  .ptf-team-member__position {
    font-size: 0.9rem;
  }

  .ptf-team-member__description {
    font-size: 0.8rem;
  }

  /* Adjust margins for smaller screens */
  .ptf-team-member {
    margin: 0.5rem;
  }
}
