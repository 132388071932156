.carousel-background {
  position: relative;
  width: 100%;
  height: 90vh;
  overflow: hidden;
}

@media (max-width: 900px) {
  .carousel-background {
    height: 40vh; /* Adjust this to your preferred height */
  }
}

.carousel-background img {
  width: 100%;
  height: auto;
  object-fit: cover;
  object-position: bottom;
  filter: brightness(50%); /* Darkens the image */
  z-index: 0; /* Ensure the image is behind the text */
}

.carousel-slide {
  position: relative;
  width: 100%;
  height: 100vh;
}

.carousel-text {
  position: absolute;
  top: 150px;
  left: 150px;
  z-index: 1; /* Text above everything */
  /*color: #FF4500; !* FainTech red *!*/
  font-family: 'Poppins', sans-serif;
}

.carousel-text h2 {
  font-size: 4rem;
  margin-bottom: 2.5rem;
  letter-spacing: 1px;
  line-height: 1.1;
  font-weight: bold;
  text-transform: uppercase;
  color: #FF4500; /* FainTech red */
  text-shadow: 4px 4px 8px rgba(0, 0, 0, 0.5); 
  /* Shadow for readability */
  max-width: 800px; /* Limit paragraph width */
}

.carousel-text p {
  font-size: 1.6rem;
  margin-top: 1rem;
  color: #ECECEC;
  letter-spacing: 1px;
  line-height: 1.6;
  text-shadow: 2px 2px 4px rgba(255, 255, 255, 0.2); /* Light shadow for readability */
  max-width: 600px; /* Limit paragraph width */
  text-align: left; /* Align text (can also use center or right) */
}

@media (max-width: 900px) {

  .carousel-text {
    top: 50px;
    left: 30px;
  }

  .carousel-text p {
    margin-top: 5rem;

    font-size: 1rem;
  }

  .carousel-text h2 {
    margin-top: 0;
    font-size: 1.2rem;
  }
}


.carousel-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3); /* Semi-transparent overlay */
  z-index: 0; /* Ensure overlay is behind the text */
}

.carousel-background .first-image {
  object-fit: cover;
  object-position: 0 80%; /* Position image at the top */
}

.carousel-background  .second-image {
  object-fit: cover;
  object-position: 0 40%; /* Position image at the top */
}

.carousel-background  .third-image {
  object-fit: cover;
  object-position: 0 50%; /* Position image at the top */
}

.carousel-background  .fourth-image {
  object-fit: cover;
  object-position: 0 0; /* Position image at the top */
}

.carousel-background  .fifth-image {
  object-fit: cover;
  object-position: 0 60%; /* Position image at the top */
}
